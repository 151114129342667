import Placeholder, { Rect } from "components/Placeholder";

const StrainQuizCTAPlaceholder: React.FC<{
  className?: string;
}> = ({ className = "" }) => (
  <Placeholder width="100%" height="60" className={className}>
    <Rect y="0" rx="4" ry="4" width="100%" height="100%" />
  </Placeholder>
);

export default StrainQuizCTAPlaceholder;
