import React, { useState } from "react";
import ReactJWPlayer from "react-jw-player";

import useOnComponentInView from "hooks/useOnComponentInView";

type Props = {
  playerId: string;
  playerScript: string;
  playlist?: string;
  file?: string;
};

const Video = ({
  playerId,
  playerScript,
  playlist,
  file,
}: Props): JSX.Element => {
  if (!playlist && !file) {
    throw new Error("Video component must have playlist or file defined");
  }

  const [visible, setVisible] = useState(false);
  const [ref] = useOnComponentInView(() => setVisible(true), {
    triggerOnce: true,
  });

  return (
    <div className="bg-leafly-white image--16:9 relative" ref={ref}>
      {visible && (
        <ReactJWPlayer
          playerId={playerId}
          playerScript={playerScript}
          playlist={playlist}
          file={file}
          className="absolute w-full h-full"
        />
      )}
    </div>
  );
};

export default Video;
