import React from "react";
import isEmpty from "lodash/isEmpty";
import Link from "next/link";

import { Action, Category } from "constants/events";
import { useStrainPageContext } from "context/StrainPageContext";
import { StrainReview } from "custom-types/Reviews";
import { StrainV2 } from "custom-types/StrainV2";
import { numberCommaSeparated } from "utils/numberCommaSeparated";
import { trackEvent } from "utils/trackEvent";

import StrainReviewCardContainer from "components/StrainReviewCardContainer";
import TrackImpression from "components/TrackImpression";

import Section from "./Section";
import StrainReviewsNoResults from "./StrainReviewsNoResults";

interface StrainReviewsProps {
  reviews: StrainReview[];
}

const StrainReviews: React.FC<StrainReviewsProps> = ({ reviews }) => {
  const { strain } = useStrainPageContext();

  const { name, slug, reviewCount, rating } = strain;

  const renderReviewSchema = () => {
    if (reviewCount > 0) {
      return (
        <div
          itemProp="aggregateRating"
          itemScope
          itemType="http://schema.org/AggregateRating"
        >
          <meta itemProp="reviewCount" content={`${reviewCount}`} />
          <meta itemProp="bestRating" content={"5"} />
          <meta itemProp="ratingValue" content={`${rating}`} />
        </div>
      );
    }
  };

  if (isEmpty(strain)) {
    return <></>;
  }

  return (
    <>
      {renderReviewSchema()}
      <TrackImpression
        label="strain page reviews carousel view"
        category="Carousel"
      >
        <Section
          id="strain-reviews-section"
          testid="strain-reviews-section"
          className="container"
          title={`${name} strain reviews`}
          tidbit={numberCommaSeparated(reviewCount, "No Reviews")}
        >
          <TrackImpression category="Reviews" label="strain write a review CTA">
            <Link
              prefetch={false}
              href={`/strains/${slug}/reviews/write-review`}
              className="button button--secondary"
              onClick={() =>
                trackEvent(
                  Category.reviews,
                  Action.click,
                  "strain write a review CTA",
                )
              }
            >
              write a review
            </Link>
          </TrackImpression>
          <div className="py-lg">
            {reviews.length > 0 ? (
              <>
                {reviews.map((review) => (
                  <StrainReviewCardContainer
                    review={review}
                    strain={strain as StrainV2}
                    key={`review-card-${review.id}`}
                    truncate
                  />
                ))}
              </>
            ) : (
              <StrainReviewsNoResults strainSlug={slug} />
            )}
          </div>
          {reviews.length > 0 && (
            <Link
              prefetch={false}
              href={`/strains/${slug}/reviews`}
              className="pt-lg font-sm font-bold text-green underline"
            >
              Read all reviews
            </Link>
          )}
        </Section>
      </TrackImpression>
    </>
  );
};

export default StrainReviews;
