import { Strain } from "custom-types/Strain";
import { getTopEffects } from "utils/strains/effectShoppingUtils";

export const getShopNowUrl = (strain: Strain, withEffects?: boolean) => {
  let params: URLSearchParams;
  if (withEffects) {
    const topEffects = getTopEffects(strain.effects);

    params = new URLSearchParams({
      boost: "strain_name",
      ...(strain?.name && { "boost_options[strain_name]": strain.name }),
      fulfillmentPreference: "pickup",
    });

    topEffects.forEach((effect) => {
      params.append("top_strain_effects_included", effect);
    });
  } else {
    params = new URLSearchParams({
      fulfillmentPreference: "pickup",
      "strain_name[]": strain.name,
    });
  }

  return `/shop?${params.toString()}`;
};
