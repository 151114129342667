import React, { useState } from "react";

import { terpData } from "constants/strains";
import { useStrainPageContext } from "context/StrainPageContext";
import { Terp } from "custom-types/Strain";
import useOnComponentInView from "hooks/useOnComponentInView";
import { moveObjToSortedArray } from "utils/moveObjToSortedArray";

import BeakerIcon from "components/Icons/beaker.svg";
import TerpeneIcon from "components/Icons/terpene.svg";
import Section from "components/StrainPage/Section";
import TooltipCTA from "components/StrainPage/TooltipCTA";

const StrainTerpenes = (): JSX.Element => {
  const {
    strain: { terps, name },
  } = useStrainPageContext();

  const [loading, setLoading] = useState(true);

  const [ref] = useOnComponentInView(() => setLoading(false), {
    triggerOnce: true,
  });

  const showSection = Object.values(terps).some((terp) => terp?.score > 0);

  if (!showSection) {
    return <></>;
  }

  const sortedTerps = moveObjToSortedArray(terps, "score").filter(
    (terp) => terp.score > 0,
  );
  const topTerp = sortedTerps[0];
  const otherTerps = (sortedTerps.length >= 3 && sortedTerps.slice(1, 3)) || [];
  const topTerpName = topTerp.name;

  return (
    <div id="strain-terpenes-section" ref={ref}>
      {!loading && sortedTerps.length ? (
        <Section
          title={`${name} terpenes`}
          id="strain-terpenes-section"
          className="container"
        >
          <TooltipCTA
            title="Certified Strain Science"
            icon={<BeakerIcon width="22" height="22" />}
            tooltipText="Terpenes not only determine the flavor and aroma of a cannabis strain, they may also modify its effects. We’ve sourced this terpene data from products tested in Leafly Certified labs."
            sectionName="terpenes"
          />
          <p className="mt-lg mb-md">
            The most abundant terpene in {name} is
            <TerpInfo topTerp={topTerp} otherTerps={otherTerps} />
          </p>
          <div className="row">
            <div className="md:col-1/2">
              <p className="uppercase font-bold text-xs font-extrabold hidden md:block">
                Dominant terpene
              </p>
              <div className="flex relative">
                <div className="mr-sm" style={{ width: 8 }}>
                  <TerpeneIcon
                    className={`text-${topTerp.name.toLowerCase()} absolute`}
                    height="100%"
                    width="8"
                  />
                </div>
                <div>
                  <p className="mb-none">
                    <span className="font-bold">{`${topTerp.name}`}</span>
                    <span className="text-grey">{` (${terpData[topTerpName].flavor})`}</span>
                  </p>
                  <p className="text-xs mb-none">{topTerp.description}</p>
                </div>
              </div>
            </div>
            {otherTerps.length > 0 && (
              <div className="md:col-1/2 mt-md md:mt-none">
                <p className="uppercase font-bold text-xs font-extrabold hidden md:block">
                  Other terpenes
                </p>
                {otherTerps.map((terp) => (
                  <div className="flex relative mb-sm" key={terp.name}>
                    <div className="mr-sm" style={{ width: 8 }}>
                      <TerpeneIcon
                        className={`text-${terp.name.toLowerCase()} absolute`}
                        height="100%"
                        width="8"
                      />
                    </div>
                    <p className="mb-none">
                      <span className="font-bold">{terp.name}</span>
                      <span className="text-grey">{` (${
                        terpData[terp.name].flavor
                      })`}</span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Section>
      ) : (
        <></>
      )}
    </div>
  );
};

type TerpProps = {
  topTerp: Terp;
  otherTerps: Terp[];
};

const TerpInfo = ({ topTerp, otherTerps }: TerpProps): JSX.Element => {
  if (topTerp && otherTerps.length === 2) {
    return (
      <span>
        <strong> {topTerp?.name.toLowerCase()}</strong>, followed by{" "}
        <strong>{otherTerps[0]?.name.toLowerCase()}</strong> and{" "}
        <strong>{otherTerps[1]?.name.toLowerCase()}</strong>.{" "}
      </span>
    );
  } else if (topTerp && otherTerps.length === 1) {
    return (
      <span>
        <strong>{topTerp?.name.toLowerCase()}</strong>, followed by{" "}
        <strong>{otherTerps[0]?.name.toLowerCase()}</strong>.{" "}
      </span>
    );
  } else {
    return (
      <span>
        <strong>{topTerp?.name.toLowerCase()}</strong>.{" "}
      </span>
    );
  }
};

export default StrainTerpenes;
