import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";

import { useStrainPageContext } from "context/StrainPageContext";

import StarRating from "components/botanic/StarRating";

interface ReviewCTAProps {
  showRedesign?: boolean;
  starLink?: string;
  strainDetailsEventTracking?: () => void;
}

const ReviewCTA: React.FC<ReviewCTAProps> = ({
  showRedesign = false,
  starLink = "#strain-reviews-section",
  strainDetailsEventTracking,
}): JSX.Element => {
  const {
    strain: { reviewCount = 0, averageRating = 0, slug },
  } = useStrainPageContext();

  return reviewCount > 0 ? (
    <StarRating
      className="text-sm"
      starWidth={showRedesign ? 18 : 16}
      rating={Math.round(averageRating * 10) / 10}
      ratingCount={reviewCount}
      showRedesign={showRedesign}
      starLink={starLink}
      starLinkTracking={() => {
        strainDetailsEventTracking && strainDetailsEventTracking();
        trackEvent("Link", "Click", "Reviews");
      }}
    />
  ) : slug ? (
    <Link
      prefetch={false}
      href={`/strains/${slug}/reviews/write-review`}
      onClick={() => {
        trackEvent("Reviews Link", "Click", "Write a Review - Empty State");
      }}
      className="text-green underline"
    >
      Be the first to review!
    </Link>
  ) : (
    <></>
  );
};

export default ReviewCTA;
