import React from "react";
import { trackEvent } from "@leafly-com/web-utils";

import Button from "components/botanic/Button";
import { DecisionPointModal } from "components/botanic/Modal/DecisionPointModal";
import TrackImpression from "components/TrackImpression";

type FavoriteModalProps = {
  closeModal: () => void;
  category: string;
  favoritedRedirect: () => void;
};

const FavoriteModal: React.FC<FavoriteModalProps> = ({
  closeModal,
  category,
  favoritedRedirect,
}) => {
  return (
    <DecisionPointModal
      onDismissed={closeModal}
      aria-labelledby="add-strain-to-favorites"
      title="Thank you!"
    >
      <TrackImpression category={category} label="Favorite CTA">
        <p className="text-center px-md pt-md">
          Your review is pending approval. In the meantime,
          <span className="mx-xs" role="img" aria-label="heart">
            💚️
          </span>
          this strain for recommendations and updates.
        </p>
        <Button
          className="mt-xl mx-auto mb-xxl"
          onClick={() => {
            trackEvent(category, "Click", "Favorite CTA");
            favoritedRedirect();
          }}
        >
          heart this strain
        </Button>
      </TrackImpression>
    </DecisionPointModal>
  );
};

export default FavoriteModal;
