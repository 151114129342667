import React from "react";
import Link from "next/link";

import NoResults from "../NoResults";

type Props = {
  strainSlug: string;
};

const StrainReviewsNoResults = ({ strainSlug }: Props) => (
  <NoResults sectionName="strain-reviews-no-results">
    <p>This strain hasn't been reviewed yet!</p>
    <p>
      <Link
        prefetch={false}
        href={`/strains/${strainSlug}/reviews/write-review`}
        className="no-underline font-bold text-green"
      >
        Click here
      </Link>{" "}
      to be the first person to review it!
    </p>
  </NoResults>
);

export default StrainReviewsNoResults;
