import React, { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import toArray from "lodash/toArray";
import Link from "next/link";

import {
  Cannabinoid,
  StrainAttributes,
  StrainRelatives,
  StrainV2,
  Terps,
} from "custom-types/StrainV2";

export const generatedDefaultDescription = (
  strain: StrainV2,
  isHtml = true,
): JSX.Element | string => {
  const showGenerateDescription =
    strain.name && !isEmpty(strain.terps) && !isEmpty(strain.cannabinoids);
  const requestReview = `If you've smoked, dabbed, or otherwise enjoyed this strain, ${strain.name}, before let us know! Leave a review.`;
  const strainCategory = isHtml ? (
    <Link href={`/strains/lists/category/${strain.category.toLowerCase()}`}>
      {strain.category.toLowerCase()}
    </Link>
  ) : (
    strain.category.toLowerCase()
  );

  if (strain.reviewCount > 10 || showGenerateDescription) {
    const description = (
      <p>
        {strain.name}
        {strain.subtitle ? `, ${akaName(strain.subtitle)}, ` : " "}is a{" "}
        {strainCategory} weed strain
        {strainParents(strain.parents, isHtml)} {strainEffects(strain.effects)}{" "}
        {strainCannabs(strain)} {strainTerp(strain.terps)}
        {requestReview}
      </p>
    );
    return isHtml ? description : description.props.children.join("");
  }

  return isHtml ? <p>{requestReview}</p> : requestReview;
};

export const akaName = (subTitle: string | null): string | undefined => {
  if (!subTitle) {
    return;
  }
  const names = subTitle.replace("aka ", "").split(",");
  let akaText = "also known as ";

  if (names.length > 2) {
    const lastElment = names[names.length - 1];
    names.fill(` and${lastElment}`, -1);
    akaText += names.join(",") + ",";
  } else if (names.length === 2) {
    akaText += names.join(" and") + ",";
  } else {
    akaText += names[0] + ",";
  }

  return akaText;
};

export const strainParents = (
  strainParents: StrainRelatives = [],
  isHtml: boolean,
) => {
  if (strainParents.length < 2) {
    return ".";
  }
  const parentCrossing = " made by crossing ";

  if (isHtml) {
    return (
      <>
        {parentCrossing}{" "}
        <Link href={`/strains/${strainParents[0].slug}`}>
          {strainParents[0].name}
        </Link>{" "}
        and{" "}
        <Link href={`/strains/${strainParents[1].slug}`}>
          {strainParents[1].name}
        </Link>
        .
      </>
    );
  } else {
    return (
      parentCrossing + strainParents.map((p) => p.name).join(" and ") + "."
    );
  }
};

export const strainEffects = (
  effects: StrainAttributes,
): string | undefined => {
  if (isEmpty(effects)) {
    return;
  }

  let topReviewedEffects =
    "Reviewers on Leafly say this strain makes them feel ";

  const topResults =
    effects &&
    Object.values(effects)
      .sort((a, b) => (b.score || 0) - (a.score || 0))
      .slice(0, 3);
  const topEffects = topResults?.map((e) => e.name.toLowerCase());
  const lastElment = topEffects?.[topEffects.length - 1];
  topEffects && topEffects.fill(`and ${lastElment}`, -1);

  topReviewedEffects += topEffects?.join(", ") + ".";
  return topReviewedEffects;
};

export const strainTerp = (terps: Terps): string | undefined => {
  if (isEmpty(terps)) {
    return;
  }

  const sortedTerps = toArray(terps).sort((a, b) => b["score"] - a["score"]);
  let topTerp = "The dominant terpene in this strain is ";

  return (topTerp += sortedTerps?.[0]?.name.toLowerCase() + ". ");
};

export const strainCannabs = (strain: StrainV2): string | undefined => {
  const { cannabinoids } = strain;
  if (isEmpty(cannabinoids)) {
    return;
  }

  const results =
    cannabinoids &&
    Object.values(cannabinoids).filter(
      (cannab) => (cannab?.percentile50 || 0) > 0,
    );
  const [primary, secondary] = results?.sort(
    (a, b) => (b?.percentile50 || 0) - (a?.percentile50 || 0),
  ) as Cannabinoid[];

  let topCannabs;
  if (primary && secondary) {
    topCannabs = `${strain.name} has ${primary.percentile50}% ${primary.displayName} and ${secondary.percentile50}% ${secondary.displayName}. `;
  } else if (primary) {
    topCannabs = `${strain.name} has ${primary.percentile50}% ${primary.displayName}. `;
  }

  return topCannabs;
};

const GeneratedStrainDescription = ({
  strain,
}: {
  strain: StrainV2;
}): JSX.Element => {
  const description = useMemo(
    () => generatedDefaultDescription(strain),
    [strain.name],
  );
  return <div>{description}</div>;
};

export default GeneratedStrainDescription;
