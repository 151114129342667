import { useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import dynamic from "next/dynamic";
import Link from "next/link";

import { useStrainPageContext } from "context/StrainPageContext";
import { useGetAvailableMenuItems } from "hooks/useGetAvailableMenuItems";

import { ComparisonShoppingTablePlaceholder } from "components/ComparisonShoppingTable/ComparisonShoppingTablePlaceholder";
import WeedCartIcon from "components/Icons/weed_cart.svg";
import Placeholder, { Rect } from "components/Placeholder";

const ProductCategorySection = dynamic(
  () => import("components/ProductCategorySection"),
);
const ComparisonShoppingTable = dynamic(
  () => import("components/ComparisonShoppingTable"),
);

const ComparisonShoppingSection: React.FC<{ strainName?: string }> = ({
  strainName,
}) => {
  const { strain } = useStrainPageContext();
  const name = strainName ?? strain.name;

  const { menuItems, menuItemWeights, initialWeight, menuItemsLoading } =
    useGetAvailableMenuItems(name, "distance");

  const [showSection, setShowSection] = useState(true);

  return showSection ? (
    <div
      className="relative bg-leafly-white pt-section pb-section my-xl"
      style={{ marginBottom: "-32px" }}
    >
      <div className="weed-bag-icon flex items-center justify-center rounded-full bg-leafly-white absolute">
        <WeedCartIcon width="28" height="33" />
      </div>
      {!menuItemsLoading && menuItems?.length ? (
        <div className="container md:mx-auto" style={{ maxWidth: 500 }}>
          <h2 className="md:text-center text-md mb-md">
            Compare the price of {name} strains near you
          </h2>
          <ComparisonShoppingTable
            initialWeight={initialWeight}
            menuItems={menuItems}
            menuItemsLoading={menuItemsLoading}
            menuItemWeights={menuItemWeights}
            strainName={name}
            trackingCategory="Flower Comparison"
          />
          <div className="mt-xl text-center">
            <Link
              href={`/search/shop?shopCategory=strain&shopName=${encodeURIComponent(
                name,
              )}`}
              className="underline font-bold"
              onClick={() => {
                trackEvent(
                  "Flower Comparison",
                  "Click",
                  "Shop all products nearby",
                );
              }}
            >
              shop all products nearby
            </Link>
          </div>
        </div>
      ) : !menuItemsLoading && menuItems?.length ? (
        <div className="container md:mx-auto" style={{ maxWidth: 500 }}>
          <Placeholder
            width="450"
            height="28"
            className="mx-auto mb-md border border-light-grey rounded"
          >
            <Rect width="100%" height="100%" />
          </Placeholder>
          <ComparisonShoppingTablePlaceholder />
          <div className="mt-xl">
            <Placeholder
              width="185"
              height="20"
              className="mx-auto border border-light-grey rounded"
            >
              <Rect width="100%" height="100%" />
            </Placeholder>
          </div>
        </div>
      ) : (
        <ProductCategorySection
          data-testid="comp-shop-empty"
          onClick={(category: string) => {
            trackEvent(
              "Flower Comparison",
              "Click",
              `empty state - ${category}`,
            );
          }}
          setShowSection={(showSection: boolean) => setShowSection(showSection)}
          strainName={name}
          trackImpressionCategory="Flower Comparison"
          trackImpressionLabelPrefix="empty state"
        />
      )}
      <style jsx>{`
        .weed-bag-icon {
          top: -20px;
          left: calc(50% - 30px);
          height: 60px;
          width: 60px;
        }
      `}</style>
    </div>
  ) : (
    <></>
  );
};

export default ComparisonShoppingSection;
