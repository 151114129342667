import React, { useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import compact from "lodash/compact";
import dynamic from "next/dynamic";

import { useStrainPageContext } from "context/StrainPageContext";

import Image from "components/Image";

const LightBox = dynamic(() => import("components/LightBox"));

const HERO_MOBILE_SIZE = 295;
const HERO_DESKTOP_SIZE = 350;

export const HeroImage: React.FC = (): JSX.Element => {
  const { strain } = useStrainPageContext();

  const [showLightBox, setShowLightBox] = useState(false);

  const toggleLightBox = () => setShowLightBox(!showLightBox);

  const isStockImage = !!strain.stockNugImage;
  const secondaryImages =
    strain.highlightedPhotos?.map((p) => p.imageUrl) || [];
  const thumbnails = [strain.nugImage, ...secondaryImages];
  const lightboxPhotos = compact(thumbnails).map((photo, idx) => ({
    imageUrl: photo,
    ...(idx === 0 &&
      isStockImage && {
        imageTitle: `stock photo similar to ${strain.name || ""}`,
      }),
  }));

  return strain.nugImage && thumbnails.length ? (
    <div className="relative flex items-center justify-center min-h-[295px] lg:min-h-[350px]">
      <div className="strain-page-hero-image mx-auto relative w-[295px] lg:w-[350px] h-[295px] lg:h-[350px]">
        <button
          onClick={() => {
            toggleLightBox();
            trackEvent("Photos", "Click", "Strain photos");
          }}
          aria-label={`Click to view this photo of ${strain.name}`}
          data-testid="strain-light-box-image"
        >
          <Image
            alt={`Picture of ${strain.name}`}
            background
            backgroundFit="contain"
            disableLazyLoad
            preload
            sizes={[
              HERO_MOBILE_SIZE,
              null,
              null,
              HERO_DESKTOP_SIZE,
              null,
              HERO_MOBILE_SIZE,
            ]}
            src={strain.nugImage}
          />
        </button>
      </div>
      {isStockImage && (
        <div
          data-testid="hero-image-stock-photo"
          className="absolute -bottom-[22px] md:bottom-0 left-0 leading-[1rem] text-center w-full p-xs text-grey text-xs"
        >
          stock photo similar to {strain.name}
        </div>
      )}
      {showLightBox && (
        <LightBox
          images={lightboxPhotos}
          onToggleLightboxClick={toggleLightBox}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default HeroImage;
