import React, { useEffect, useRef } from "react";
import isEmpty from "lodash/isEmpty";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import { useStrainPageContext } from "context/StrainPageContext";
import { StrainV2 } from "custom-types/StrainV2";
import useDomain from "hooks/useDomain";
import { useEventTracker } from "hooks/useEventTracker";
import { getUserId } from "redux/selectors/user";

import Button from "components/botanic/Button";

const GeneratedStrainDescription = dynamic(
  () => import("components/StrainPage/GeneratedStrainDescription"),
);

const StrainDescription = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  const {
    strain: { description, name, slug, effects, flavors },
    strain,
  } = useStrainPageContext();

  const ref = useRef<HTMLDivElement | null>(null);
  const { publishEvent } = useEventTracker();
  const userId = useSelector(getUserId);

  const domain = useDomain();
  const router = useRouter();

  const handleAnchorClick = () => {
    publishEvent({
      action: Action.click,
      category: Category.strainDescriptionLink,
      strainId: strain.id,
      userId: userId,
    });
  };

  useEffect(() => {
    if (ref.current) {
      const elements = ref.current.getElementsByTagName("a");
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", handleAnchorClick);
      }
    }
    return () => {
      if (ref.current) {
        const elements = ref.current.getElementsByTagName("a");
        for (let i = 0; i < elements.length; i++) {
          elements[i].removeEventListener("click", handleAnchorClick);
        }
      }
    };
  }, [ref, handleAnchorClick]);

  if (isEmpty(strain)) {
    return <></>;
  }

  const hasNoEffects = !effects || Object.keys(effects).length < 1;
  const hasNoFlavors = !flavors || Object.keys(flavors).length < 1;
  const showReviewCta = !description || (hasNoEffects && hasNoFlavors);

  //strain-admin is adding on the targets to link. Removing here is temporary until we can
  //clean up admin-og and the data.
  const strRegExp = new RegExp(/ target="_blank"/, "g");

  const displayDescription = description
    ?.replace(name, "<b>" + name + "</b>")
    .replace(strRegExp, "");

  const mailtoHref = `mailto:strains@leafly.com?subject=Report a concern with ${
    strain.name
  }&body=Reporting a concern with ${encodeURIComponent(
    [domain, router.asPath].join(""),
  )}`;

  return (
    <div className={className} ref={ref}>
      <div
        itemProp="description"
        className="mt-lg mb-xxl"
        data-testid="strain-description-container"
      >
        <div className="green-links">
          {displayDescription ? (
            <div dangerouslySetInnerHTML={{ __html: displayDescription }} />
          ) : (
            <GeneratedStrainDescription strain={strain as StrainV2} />
          )}
        </div>
        <div className="mt-6">
          Something not right?{" "}
          <a
            href={mailtoHref}
            target="_blank"
            className="font-bold underline"
            onClick={() => {
              publishEvent({
                action: Action.click,
                category: Category.strainDetail,
                label: "suggest edit",
                strainId: strain.id,
              });
            }}
          >
            Suggest an edit
          </a>
        </div>
      </div>
      {showReviewCta && (
        <Button variant="secondary" className="mb-xl" href={`/review/${slug}`}>
          write a review
        </Button>
      )}
      <style jsx global>{`
        .green-links a {
          color: var(--color-green);
        }
      `}</style>
    </div>
  );
};

export default StrainDescription;
