import React from "react";
import {
  sendClientSideSplitTrackEvent,
  useClientSideSplitTreatments,
} from "@leafly-com/split-next";
import { useSelector } from "react-redux";

import { Strain } from "custom-types/Strain";
import { useEventTracker } from "hooks/useEventTracker";
import { getState } from "redux/selectors/location";
import isMobileDevice from "utils/isMobileDevice";
import { getSplitKey } from "utils/split/getSplitKey";
import { getShopNowUrl } from "utils/strains/getShopNowUrl";

import Button from "components/botanic/Button";
import TrackImpression from "components/TrackImpression";

const ShopStrainButton: React.FC<{
  strain: Strain;
}> = ({ strain }) => {
  const { publishEvent } = useEventTracker();
  const strainId = strain.id;
  const { treatments, isLoading } = useClientSideSplitTreatments(
    "NL-1289_strainDetailShop_test",
    getSplitKey(),
  );

  const shopcoreStrain =
    !isLoading &&
    treatments["NL-1289_strainDetailShop_test"].treatment === "shopStrain";

  const shopcoreStrainAndEffects =
    !isLoading &&
    treatments["NL-1289_strainDetailShop_test"].treatment ===
      "shopStrainEffects";

  const shopStrainHref = shopcoreStrain
    ? getShopNowUrl(strain)
    : shopcoreStrainAndEffects
      ? getShopNowUrl(strain, true)
      : "#strain-shopping";

  const userLocationState = useSelector(getState);

  return (
    <TrackImpression
      className="pt-3"
      trackingFunction={() => {
        return publishEvent({
          action: "impression",
          category: "button",
          label: "shop strain",
          strainId,
        });
      }}
    >
      <Button
        href={shopStrainHref}
        data-testid="strain-shop-button"
        onClick={() => {
          publishEvent({
            action: "click",
            category: "button",
            label: "shop strain",
            strainId,
          });
          sendClientSideSplitTrackEvent(
            {
              eventType: "NL-1289_shopStrain_click",
              properties: {
                is_mobile: isMobileDevice(),
                user_location_state: userLocationState,
              },
            },
            getSplitKey(),
          );
        }}
      >
        shop {strain.name}
      </Button>
    </TrackImpression>
  );
};

export default ShopStrainButton;
