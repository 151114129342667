import { useEffect, useState } from "react";

import getFinderDispensaries from "api/requests/getFinderDispensaries";
import getMenuItems from "api/requests/getMenuItems";
import { Coordinates } from "custom-types/Coordinates";
import { StrainV2 } from "custom-types/StrainV2";
import { getTopEffects } from "utils/strains/effectShoppingUtils";

import { StrainFeature } from "components/StrainPage/StrainFeatureAd/StrainFeatureAd";

const useStrainFeature = ({ lat, lon }: Coordinates, strain: StrainV2) => {
  const [strainFeature, setStrainFeature] = useState<StrainFeature>({
    similarEffectsCount: 0,
    store: null,
    strainMatchCount: 0,
    topEffects: [],
  });
  const [showStrainFeature, setShowStrainFeature] = useState<boolean>();

  useEffect(() => {
    const topEffects = getTopEffects(strain.effects);
    const fetchStrainFeatureData = async () => {
      const { sponsoredStores } = await getFinderDispensaries({
        lat,
        limit: 1,
        lon,
        merch_unit_name: "strain-feature",
        platinum_take: 1,
        radius: 20,
      });
      setStrainFeature({
        ...strainFeature,
        store: sponsoredStores[0],
      });

      if (sponsoredStores[0]) {
        const { totalItems: strainMatchCount } = await getMenuItems(
          sponsoredStores[0].slug,
          {
            enableNewFilters: true,
            strain_name: strain.name,
            take: 0,
          },
        );

        const { totalItems: similarEffectsCount } = await getMenuItems(
          sponsoredStores[0].slug,
          {
            enableNewFilters: true,
            take: 0,
            top_strain_effects_included: topEffects,
          },
        );
        setShowStrainFeature(similarEffectsCount > 0 || strainMatchCount > 0);
        setStrainFeature({
          similarEffectsCount,
          store: sponsoredStores[0],
          strainMatchCount,
          topEffects,
        });
      } else {
        setShowStrainFeature(false);
      }
    };
    if (topEffects.length > 0) {
      fetchStrainFeatureData();
    } else {
      setShowStrainFeature(false);
    }
  }, []);

  return { showStrainFeature, strainFeature };
};

export default useStrainFeature;
