import React, { ElementType, ReactNode } from "react";
import classNames from "classnames";
import Link from "next/link";

type SectionHeaderProps = {
  /**
   * Call-to-action text that appears on the right side of the header
   */
  action?: string | ReactNode;
  /**
   * URL the header should link to
   */
  actionHref?: string;
  /**
   * Optional onClick prop for event tracking, etc
   */
  actionOnClick?: () => void;

  /**
   * Primary section header text
   */
  children: ReactNode;
  className?: string;
  /**
   * Default header tag is an h2. Provide a `headerAs` prop to use a different tag (e.g. h1, h3, div...)
   */
  headerAs?: ElementType;

  /**
   * Default header class is "heading--m", which gives the header a 24px font size.
   * Provide a headerClassName prop to override this behavior and use a different header class.
   */
  headerClassName?: string;
  /**
   * Smalelr text that sits to right of main header text
   */
  tidbit?: string;
};

/**
 * Section headers are used as titles for sections of a page.
 */
const SectionHeader: React.FC<
  React.HTMLAttributes<HTMLDivElement> & SectionHeaderProps
> = ({
  action,
  actionHref,
  children,
  className,
  headerAs: Header = "h2",
  headerClassName,
  actionOnClick,
  tidbit,
  ...others
}) => (
  <div
    className={classNames(
      className,
      "flex items-end justify-between mb-sm relative",
    )}
    {...others}
  >
    <Header className={headerClassName}>
      {children}
      {!!tidbit && <span className="ml-sm text-green text-sm">{tidbit}</span>}
    </Header>
    {action && actionHref && (
      <Link
        href={actionHref}
        onClick={actionOnClick}
        className="text-green font-bold underline"
      >
        {action}
      </Link>
    )}
  </div>
);

export default SectionHeader;
