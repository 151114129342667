import React from "react";
import { trackEvent } from "@leafly-com/web-utils";

import SectionHeader from "components/botanic/SectionHeader";

type Props = {
  actionText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  children: any;
  className?: string;
  description?: string;
  href?: string;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  linkGaLabels?: any[];
  testid?: string;
  tidbit?: string;
  title: string;
};

const Section = ({
  actionText,
  children,
  className,
  description,
  href,
  id,
  linkGaLabels = [],
  testid = "",
  tidbit,
  title,
}: Props) => {
  const showLink = href && actionText;

  return (
    <section className={`pt-xxl ${className}`} id={id}>
      <SectionHeader
        tidbit={tidbit}
        data-testid={testid}
        {...(showLink && {
          action: actionText,
          actionHref: href,
          actionOnClick: () => {
            trackEvent(...linkGaLabels);
          },
        })}
      >
        {title}
      </SectionHeader>
      {description && <p>{description}</p>}
      {children}
    </section>
  );
};

export default Section;
