import isEmpty from "lodash/isEmpty";

import { useStrainPageContext } from "context/StrainPageContext";
import { sortTopCannabs } from "utils/sortTopCannabs";

const StrainCannabinoids: React.FC = () => {
  const { strain } = useStrainPageContext();
  const { cannabinoids } = strain;

  if (isEmpty(strain)) {
    return <></>;
  }

  const sortedTopCannabs = sortTopCannabs(cannabinoids);

  return (
    <>
      {(sortedTopCannabs?.[0]?.percentile50 || 0) > 0 &&
        sortedTopCannabs.map((cannabinoid, i) => (
          <span
            className="text-xs font-bold pt-px pl-2 border border-transparent"
            data-testid={cannabinoid.displayName}
            key={`strain-cannabinoid-${i}`}
          >
            {cannabinoid.displayName}{" "}
            {cannabinoid.percentile50 !== null &&
            (cannabinoid.percentile50 || 0) >= 0 ? (
              `${cannabinoid.percentile50}%`
            ) : (
              <>&#8212;</>
            )}
          </span>
        ))}
    </>
  );
};
export default StrainCannabinoids;
